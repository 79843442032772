import React, { useRef, useEffect, useState, useContext, useLayoutEffect } from "react";
import LocalizationContext from "../LocalizationContext";
import { MouseContext } from "../MouseContext";
import * as Utilities from '../Shared/Utilities';
import { Parser } from 'html-to-react'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { gsap } from "gsap";

import YearSeparator from '../../css/_img/crn/line-year.svg';

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const Decade = (props) => {
    const localizationCtx = useContext(LocalizationContext);
    const { cursorChangeHandler } = useContext(MouseContext);
    const [classBoxDecade, setClassBoxDecade] = useState("col-12 col-lg-11 col-xxl-10 box-decade");
    const [yachtLink, setYachtLink] = useState("");


    const myDecade = props.decade;
    const myContainer = useRef();
    const myPeriod = useRef();
    const myPeriodBar = useRef();
    const myPeriodContainer = useRef();


    const loadLink = async () => {
        const response = await fetch("/api/model/linkmodel/MEGA/" + localizationCtx.localizationData.cultureCode);

        if (response.ok) {
            const data = await response.text();
            if (data) {
                setYachtLink(data);
            }

        } else {
            const error = await response.text();
            console.log(error);
        }
    }

    useEffect(() => {
        //if (myDecade.featured === true) {
        //    setClassBoxDecade("col-10 box-decade-featured");
        //}

        loadLink();

    }, []);


    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            if (Utilities.isMobile) {
                const t1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".box-decade",
                        start: 'top 85%',
                        end: "top 65%",
                        scrub: 0.5,
                        markers: false,
                        id: "decade-scroll",
                        onToggle: self => self.isActive && setValue()
                    }
                });
            }

            if (Utilities.isTablet) {
                const t1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".box-decade",
                        start: 'top 85%',
                        end: "top 65%",
                        scrub: 0.5,
                        markers: false,
                        id: "decade-scroll",
                        onToggle: self => self.isActive && setValue()
                    }
                });

                t1.fromTo(myPeriod.current, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 })
                t1.fromTo(myPeriodBar.current, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 }, "<")

                const t1b = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".box-decade",
                        start: 'top 20%',
                        end: 'top top',
                        scrub: 0.5,
                        markers: false,
                        id: "decade-scroll-out",
                    }
                });

                t1b.to(myPeriod.current, { opacity: 0 })
                t1b.to(myPeriodBar.current, { opacity: 0 }, "<")
            }


            if (Utilities.isLaptop || Utilities.isSmallLaptop || Utilities.isTabletH) {

                const t1 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".box-decade",
                        start: 'top 85%',
                        end: "top 65%",
                        scrub: 0.5,
                        markers: false,
                        id: "decade-scroll",
                        onToggle: self => self.isActive && setValue()
                    }
                });


                t1.fromTo(myPeriod.current, { opacity: 0, scale: 0.3 }, { opacity: 1, scale: 1 })


                const t1b = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".box-decade",
                        start: 'top 25%',
                        end: 'top top',
                        scrub: 0.5,
                        markers: false,
                        id: "decade-scroll-out",
                    }
                });

                t1b.to(myPeriod.current, { x: -1000, opacity: 0 })

                const t2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".box-decade",
                        start: 'top center+=300',
                        end: "+=1000",
                        scrub: 0.5,
                        markers: false,
                        id: "period-scroll"
                    }
                });
                t2.fromTo(myPeriodBar.current, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, duration: 1 })
                t2.to(myPeriodBar.current, { opacity: 1, scale: 1, duration: 1 })
                t2.to(myPeriodBar.current, { opacity: 0.2, scale: 0.5, duration: 2 })
            }


        }, myContainer);

        return () => ctx.revert();
    }, []);


    const setValue = () => {
        props.updateValueCallBack({ startYear: myDecade.startYear, endYear: myDecade.endYear });
    }

    const getText = () => {
        let text = myDecade.textEng;
        if (localizationCtx.localizationData.cultureCode === "it-it") {
            text = myDecade.textIta;
        }
        if (localizationCtx.localizationData.cultureCode === "fr-fr") {
            text = myDecade.textFra;
        }
        if (localizationCtx.localizationData.cultureCode === "es-es") {
            text = myDecade.textSpa;
        }
        if (localizationCtx.localizationData.cultureCode === "ru-ru") {
            text = myDecade.textRus;
        }
        if (localizationCtx.localizationData.cultureCode === "ja-jp") {
            text = myDecade.textJap;
        }
        if (localizationCtx.localizationData.cultureCode === "pt-pt") {
            text = myDecade.textPor;
        }
        if (localizationCtx.localizationData.cultureCode === "de-de") {
            text = myDecade.textDeu;
        }
        if (localizationCtx.localizationData.cultureCode === "zh-cn") {
            text = myDecade.textChi;
        }

        if (text.length > 1) {

            return (
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                        bulletClass: 'swiper-pagination-bullet-gallery',
                        bulletActiveClass: 'swiper-pagination-bullet-gallery-active',
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '"></span>';
                        },
                    }}
                >
                    {text.map((t, i) => (
                        <SwiperSlide key={i}>
                            <div className="text-decade-container">
                                <p className="text-decade">
                                    {Parser().parse(t)}
                                </p>
                                {myDecade.id === "decade005" && (i === text.length - 1) &&
                                    <div className="action-button discover-yacht-button pt-3" onMouseEnter={() => cursorChangeHandler("hide")} onMouseLeave={() => cursorChangeHandler("")}>
                                        <a href={yachtLink} target="_parent">{Parser().parse(localizationCtx.localizationData.actionDiscoverYachts)}</a>
                                    </div>
                                }
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            );


        } else {
            return (
                <div className="text-decade-container">
                    <p className="text-decade">
                        {Parser().parse(text[0])}
                    </p>
                </div>
            );
        }
    }

    return (
        <div ref={myContainer} id={myDecade.id}>
            <div className="row text-center" ref={myPeriodContainer}>
                <div className="col-lg-1 col-xxl-2">
                    <div className="period-decade-fixed" ref={myPeriodBar}>
                        <p className="text-period" >
                            {myDecade.startYear} <img src={YearSeparator} alt="separator" /> {Utilities.FormatEndYear(myDecade.endYear, localizationCtx.localizationData.cultureCode)}
                        </p>
                        <div className="text-period-bar-fixed"></div>
                    </div>
                </div>
                <div className={classBoxDecade} ref={myPeriod}>
                    {getText()}
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12"><p>&nbsp;</p></div>
            </div>
        </div>
    );
};
export default Decade;

