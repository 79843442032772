import React, { useRef, useEffect, useState, useContext, useLayoutEffect } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { MouseContext } from "../MouseContext";

import { gsap, Power1, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);



export const ImgBoat = (props) => {
    const { cursorChangeHandler } = useContext(MouseContext);
    const isMobile = useMediaQuery('(max-width:920px)');

    const myImgBoat = useRef();

    useEffect(() => {
        myImgBoat.current.addEventListener("click", handleZoomImage);
    }, []);


    const handleZoomImage = () => {

        const rect = myImgBoat.current.getBoundingClientRect();

        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        let dw = Math.round(vw * 0.85);
        let dh = Math.round(vh * 0.85);

        let dx = Math.round((vw - dw) / 2) - rect.left;
        let dy = Math.round((vh - dh) / 2) - rect.top;

        const paramImageTo = { dx: dx, dy: dy, top: 0, left: 0, width: dw, height: dh };
        const paramImageFrom = { dx: -dx, dy: -dy, top: rect.top, left: rect.left, width: rect.width, height: rect.height }; 

        props.animateZoomCallBack(props.imageurl, paramImageTo, paramImageFrom, myImgBoat.current);

    };

    return (
        <div>
            <img src={props.imageurl} className="img-gallery" ref={myImgBoat} onMouseEnter={() => cursorChangeHandler("zoom-in")} onMouseLeave={() => cursorChangeHandler("")} />
        </div>
    );
};

export default ImgBoat;

