import React, { Component } from 'react';
import { Container } from '@mui/material';


export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}
