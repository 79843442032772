import React from "react";
import { useContext } from "react";
import "./CursorCrnHeritage.css";

import useMousePosition from "./UseMousePosition";
import { MouseContext } from "../MouseContext";
import LocalizationContext from "../LocalizationContext";

const CursorCrnHeritage = () => {
    const { cursorType } = useContext(MouseContext);
    const localizationCtx = useContext(LocalizationContext);
    const { x, y } = useMousePosition();
    return (
        <>
            <div
                className={`ring ${cursorType} d-flex align-items-center justify-content-center`}
                style={{ left: `${x}px`, top: `${y}px` }}
            >
                {cursorType === "hovered" &&
                    <p className="text-mouse-cursor">{localizationCtx.localizationData.actionDiscover}</p>
                }

                {cursorType === "zoom-in" &&
                    <p className="text-mouse-cursor">+</p>
                }

                {cursorType === "zoom-out" &&
                    <p className="text-mouse-cursor">-</p>
                }

            </div>
            <div
                className={`dot ${cursorType}`}
                style={{ left: `${x}px`, top: `${y}px` }}
            ></div>
        </>
    );
};
export default CursorCrnHeritage;