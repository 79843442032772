import { format } from 'date-fns';

//Formatting
export const GetDateItalianFormat = (date) => {
    if (date && date!=="") {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString("it-IT", options);
    }
    return "";
};

export const GetAmountEuroFormat = (amount) => {
    if (amount && amount !== "") {
        return "€ " + Number(amount).toLocaleString("it-IT", { minimumFractionDigits: 2 });
    }
    return "";
};

export const GetAmountDecimalFormat = (amount) => {
    if (amount && amount !== "") {
        return Number(amount).toLocaleString("it-IT", { minimumFractionDigits: 2 });
    }
    return "";
};

export const GetAmountToDecimal = (amount) => {
    if (amount && amount !== "") {
        amount = amount.replace('.', '');
        amount = amount.replace(',', '.');
        return amount;
    }
    return "";
};

// Date & Time
export const GetDateValue = (sourceDateValue) => {
    try {
        if (!sourceDateValue) throw "Invalid date";

        let myDate = new Date(sourceDateValue);

        if (myDate.getFullYear() <= 1900) {
            throw "Invalid date";
        }

        return format(sourceDateValue, "yyyy-MM-dd");
    } catch {
        return undefined;
    }

};

export const DiffTwoDatesInDays = (firstDate, secondDate) => {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);

    const diffInDays = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));
    return diffInDays;
};


//Validation
export const IsValidPhoneNumber = (phoneNumberString) => {
    const re = /^(\+[0-9]{1,3}\x20{0,1}){0,1}[0-9]{4,14}$/;
    return re.test(phoneNumberString);
}

export const IsValidPassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    return re.test(password);
}

export const priceStringToNumberString = (priceString) => {
    if (priceString && priceString!=="") {
        return priceString.replace(",", ".");
    }
    return 0;
}

export const BooleanToString = (boolValue) => {
    if (boolValue) {
        return "Sì";
    } else {
        return "No";
    }

    return "-";
}


export const FormatEndYear = (endYear, cultureCode) => {
    if (endYear === 9999) {
        let today = "Today";

        if (cultureCode === "it-it") {
            today = "Today";
        }
        if (cultureCode === "fr-fr") {
            today = "Today";
        }
        if (cultureCode === "es-es") {
            today = "Today";
        }
        if (cultureCode === "ru-ru") {
            today = "Today";
        }
        if (cultureCode === "ja-jp") {
            today = "Today";
        }
        if (cultureCode === "pt-pt") {
            today = "Today";
        }
        if (cultureCode === "de-de") {
            today = "Today";
        }
        if (cultureCode === "zh-cn") {
            today = "现在";
        }
        return today;
    } else {
        return endYear;
    }
}


export const isMobile = window.matchMedia("(max-width : 576px)").matches;
export const isTablet = window.matchMedia("(min-width : 577px) and (max-width : 992px)").matches;
export const isTabletH = window.matchMedia("(min-width : 993px) and (max-width : 1200px)").matches;
export const isSmallLaptop = window.matchMedia("(min-width : 1201px) and (max-width : 1400px)").matches;
export const isLaptop = window.matchMedia("(min-width : 1401px)").matches;

export const GetScrollToYOffset = (container) => {
    if (isMobile) {
        let offset = document.documentElement.clientHeight * 0.20 + 70;
        return offset;
    } else {
        if (isTablet) {
            return 270;
        } else {
            return 250;
        }
    }
};

