import React, { useRef, useEffect, useState, useContext, useLayoutEffect } from "react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import LocalizationContext from "../LocalizationContext";
import { MouseContext } from "../MouseContext";
import * as Utilities from '../Shared/Utilities';

import ImgBoat from './ImgBoat'; 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { gsap, Power1, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);



export const CardBoat = (props) => {
    const localizationCtx = useContext(LocalizationContext);
    const { cursorChangeHandler } = useContext(MouseContext);

    const [boatData, setBoatData] = useState(props.boatData);
    const [dataPosition, setDataPosition] = useState(props.dataPosition);
    const [imagePosition, setImagePosition] = useState(props.imagePosition);
    const [withLink, setWithLink] = useState(props.withLink);

    const [boatLink, setBoatLink] = useState("");
    const [numImages, setNumImages] = useState(0);

    const classNameBoatName = "pb-2 pb-xl-5 boat-name-" + dataPosition;
    const classNameMainData = "col-12 boat-main-data-" + dataPosition;
    const [classNameBoxBoatImage, setClassNameBoxBoatImage] = useState("col-12 box-boat-image-" + imagePosition);
    const [imgBoatStyle, setImgBoatStyle] = useState("image-boat-props image-boat-props-" + imagePosition);
    const [imgBoatContainerStyle, setImgBoatContainerStyle] = useState("");
    const [imgBackgroundUrl, setImgBackgroundUrl] = useState("");
    const [photogallerySwiperEnabled, setPhotogallerySwiperEnabled] = useState(true);

    const myContainer = useRef();
    const myBoat = useRef();
    const myImgContainer = useRef();
    const myBoatImage = useRef();
    const myBoatImageZoom = useRef();
    const myBoatZoom = useRef();
    const myActionBack = useRef();
    const myBoatMainData = useRef();

    const myBoatNameZoom = useRef();
    const myBoatDataZoom = useRef();
    const myActionZoom = useRef();
    const myBoatPhotogallery = useRef();

    const myContainerImageGalleryZoom = useRef();
    const myImageGalleryZoom = useRef();

    const [myImage, setMyImage] = useState("");
    const myImageElement = useRef();
    const myParamImageFrom = useRef();
    const myParamImageTo = useRef();



    const loadLink = async () => {
        if (boatData.link !== "") {
            const response = await fetch("/api/model/linkmodel/" + boatData.link + "/" + localizationCtx.localizationData.cultureCode);

            if (response.ok) {
                const data = await response.text();
                if (data) {
                    setBoatLink(data);
                }

            } else {
                const error = await response.text();
                console.log(error);
            }
        }
    }

    const setBackgroundImageZoom = () => {
        if (Utilities.isMobile || Utilities.isTablet) {
            switch (props.boatData.decade) {
                case "decade001":
                    setImgBackgroundUrl("/images/crn/img-default-001.jpg");
                    break;
                case "decade002":
                    setImgBackgroundUrl("/images/crn/img-default-002.jpg");
                    break;
                case "decade003":
                    setImgBackgroundUrl("/images/crn/img-default-003.jpg");
                    break;
                case "decade004":
                    setImgBackgroundUrl("/images/crn/img-default-004.jpg");
                    break;
                default:
                    setImgBackgroundUrl(props.boatData.mainImage);
            }
        } else {
            setImgBackgroundUrl(props.boatData.mainImage);
        }
    }

    const adjustPhotogallery = () => {

        if (Utilities.isMobile || Utilities.isTablet) {
            if (!props.boatData.photoGallery && !props.boatData.noImage) {
                props.boatData.photoGallery = true;
                props.boatData.imagesList = [{ "url": props.boatData.mainImage }]
                setPhotogallerySwiperEnabled(false); // Only one image, so swiper not necessary, pagination will be hidden
            }
        }
    }

    useEffect(() => {
        let mainStyle = "image-boat-props";
        let mainStylePosition = "image-boat-props-" + imagePosition;
        let colorStyle = "";

        if (boatData.noImage === true) {
            mainStyle = "image-boat-props-noimage";
        }

        let isTouchDevice = window.matchMedia("(any-hover: none)").matches;

        if ((boatData.mainImageColor === true) && !isTouchDevice) {
            colorStyle = "image-boat-props-color";
        }

        const imageFinalStyle = mainStyle + " " + mainStylePosition;

        setImgBoatStyle(imageFinalStyle);
        setImgBoatContainerStyle(colorStyle);

        if (boatData.photoGallery === true) {
            if (Utilities.isMobile || Utilities.isTablet) {
                setNumImages(1);
            } else {
                //if (boatData.imagesList.length > 4) {
                //    setNumImages(4);
                //} else {
                //    setNumImages(boatData.imagesList.length);
                //}
                setNumImages(3);
            }
        }

        myImgContainer.current.addEventListener("click", handleViewCard);
        myActionBack.current.addEventListener("click", handleViewCardCallBack);
        myImageGalleryZoom.current.addEventListener("click", animateZoomCallBackRevert);

        let tempClassBoxBoatImage = "col-12 box-boat-image-";
        if (Utilities.isMobile) {
            setClassNameBoxBoatImage(tempClassBoxBoatImage + dataPosition);
        } else {
            setClassNameBoxBoatImage(tempClassBoxBoatImage + imagePosition);
        }

        loadLink();
        setBackgroundImageZoom();
        adjustPhotogallery();

    }, []);

    const filterChangeHandler = (flag) => {
        if (boatData.mainImageColor === true) {
            let tempStyle;

            if (flag === true) {
                tempStyle = "image-boat-props-color image-boat-props-color-overlap";
            } else {
                tempStyle = "image-boat-props-color";
            }

            setImgBoatContainerStyle(tempStyle);
        }

    };

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {


            if (Utilities.isLaptop || Utilities.isSmallLaptop || Utilities.isTabletH || Utilities.isTablet) {

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 90%',
                        end: 'top 65%',
                        scrub: 0.5,
                        markers: false,
                        id: "boat-in",
                        onToggle: self => self.isActive && setValue(),
                        onEnter: self => setProgressBar(self.direction),
                        onEnterBack: self => setProgressBar(self.direction),
                    }
                });

                tl.to(myBoat.current, { opacity: 1, scale: 1 })



                const t2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 25%',
                        end: 'top top-=100',
                        scrub: 0.5,
                        markers: false,
                        id: "boat-out",
                    }
                });

                t2.to(myBoat.current, { opacity: 0.2, scale: 0.5 })

            }

            if (Utilities.isMobile) {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 90%',
                        end: 'top 65%',
                        scrub: 0.5,
                        markers: false,
                        id: "boat-in",
                        onToggle: self => self.isActive && setValue(),
                    }
                });

                tl.to(myBoat.current, { opacity: 1 })

                const t2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 25%',
                        end: 'top top-=100',
                        scrub: 0.5,
                        markers: false,
                        id: "boat-out",
                    }
                });

                t2.to(myBoat.current, { opacity: 0.2 })
            }



        }, myContainer);

        return () => ctx.revert();
    }, []);


    const setValue = () => {
        props.updateValueCallBack(boatData.yearLaunch, { startYear: boatData.refDecade.startYear, endYear: boatData.refDecade.endYear });
    };

    const setProgressBar = (direction) => {
        props.updateProgressBarCallBack(direction * 0.25 / props.boatsPerDecade);
    }

    const handleViewCard = () => {
        animateBoatCard(myBoatImage.current, myBoatImageZoom.current, myBoatZoom.current, true);
    };

    const handleViewCardCallBack = () => {
        animateBoatCard(myBoatImageZoom.current, myBoatImage.current, myBoatZoom.current, false);
    };

    const animateBoatCard = (fromImage, toImage, zoomCard, zoomIsVisible) => {

        const tl = gsap.timeline();

        const clone = fromImage.cloneNode();

        document.body.appendChild(clone);

        const from = calculatePosition(fromImage);
        const to = calculatePosition(toImage);

        if (zoomIsVisible === false) {
            tl.set(zoomCard, { visibility: "hidden" });
        }

        tl.set([fromImage, toImage], { visibility: "hidden" });
        tl.set(clone, { position: "absolute", margin: 0, zIndex: 99 });

        let dx = to.left - from.left;
        let dy = to.top - from.top;

        let mainDataOpacity = 0;
        let periodBoxOpacity = 0;
        if (zoomIsVisible === false) {
            mainDataOpacity = 1;
            periodBoxOpacity = 1;
        }



        const onCompleteAnimation = () => {
            document.body.removeChild(clone);
            if (zoomIsVisible == true) {
                animateBoatMainData();
            }
        }

        const style = {
            x: dx,
            y: dy,
            width: to.width,
            height: to.height,
            opacity: 0.2,
            autoRound: false,
            ease: Power4.easeOut,
            onComplete: onCompleteAnimation
        };

        const styleMobile = {
            x: dx,
            y: dy,
            width: to.width,
            height: to.height,
            opacity: 0,
            autoRound: false,
            ease: Power4.easeOut,
            onComplete: onCompleteAnimation
        };


        tl.set(clone, from);

        if (Utilities.isLaptop || Utilities.isSmallLaptop || Utilities.isTabletH) {
            tl.to(clone, 1, style);
        } else {
            tl.to(clone, 1, styleMobile);
        }
        tl.to(myBoatMainData.current, { duration: 1, opacity: mainDataOpacity }, "<");

        props.animateCallBack(periodBoxOpacity);

        if (zoomIsVisible === true) {
            tl.set(zoomCard, { visibility: "visible" });

        }
        tl.set(toImage, { visibility: "visible" });

    };

    const animateBoatMainData = () => {
        const tl = gsap.timeline();
        tl.fromTo(myBoatNameZoom.current, { opacity: 0 }, { opacity: 1, duration: 1 });
        tl.fromTo(myBoatDataZoom.current, { opacity: 0 }, { opacity: 1, duration: 1 }, "<");
        tl.fromTo(myActionZoom.current, { opacity: 0 }, { opacity: 1, duration: 1 }, "<");
        if (boatData.photoGallery === true) {
            tl.fromTo(myBoatPhotogallery.current, { scale: 0 }, { scale: 1, duration: 1 }, 1);
        }
    };


    const offsetImageZoomY = 50; // Due to embedded solution into DNN

    const animateZoomCallBack = (imageUrl, paramImageTo, paramImageFrom, originalElement) => {
        // Animation for images in photogallery
        // Animation not available for mobile device
        if (!Utilities.isMobile && !Utilities.isTablet) {
            setMyImage(imageUrl);

            myParamImageTo.current = paramImageTo;
            myParamImageFrom.current = paramImageFrom;
            myImageElement.current = originalElement;

            const tl = gsap.timeline();
            tl.set(myContainerImageGalleryZoom.current, { visibility: "visible" });

            const onCompleteAnimation = () => {
                const finalImageRect = myImageGalleryZoom.current.getBoundingClientRect();
                myParamImageTo.current.left = finalImageRect.left;
                myParamImageTo.current.top = finalImageRect.top;
            }

            const styleTo = {
                x: paramImageTo.dx,
                y: paramImageTo.dy + offsetImageZoomY,
                width: paramImageTo.width,
                height: paramImageTo.height,
                autoRound: false,
                ease: Power4.easeOut,
                onComplete: onCompleteAnimation
            };
            tl.set(myImageGalleryZoom.current, { position: "absolute" });
            tl.set(myImageGalleryZoom.current, paramImageFrom);
            tl.to(myImageGalleryZoom.current, styleTo);
            tl.to(originalElement, { opacity: 0 }, "<");

        }

    };

    const animateZoomCallBackRevert = () => {
        const tl = gsap.timeline();

        const onCompleteAnimation = () => {
            gsap.set(myImageGalleryZoom.current, { clearProps: "all" }); // Clean all properties added during the double animation (zoom-in and zoom-out)
        }

        const styleTo = {
            x: myParamImageFrom.current.dx,
            y: myParamImageFrom.current.dy - offsetImageZoomY,
            width: myParamImageFrom.current.width,
            height: myParamImageFrom.current.height,
            autoRound: false,
            ease: Power4.easeOut,
            onComplete: onCompleteAnimation
        };

        tl.set(myImageGalleryZoom.current, { position: "absolute" });
        tl.set(myImageGalleryZoom.current, myParamImageTo.current);
        tl.to(myImageGalleryZoom.current, styleTo);
        tl.to(myImageElement.current, { opacity: 1 }, "<");

        tl.set(myContainerImageGalleryZoom.current, { visibility: "hidden" });

    };

    const calculatePosition = (element) => {

        var rect = element.getBoundingClientRect();

        var scrollTop = window.pageYOffset || document.body.scrollTop || 0;
        var scrollLeft = window.pageXOffset || document.body.scrollLeft || 0;

        var clientTop = document.body.clientTop || 0;
        var clientLeft = document.body.clientLeft || 0;

        return ({
            top: Math.round(rect.top + scrollTop - clientTop),
            left: Math.round(rect.left + scrollLeft - clientLeft),
            height: rect.height,
            width: rect.width,
        })
    };

    return (
        <div id={props.idBoat} ref={myContainer}>
            <div className="boat-card" ref={myBoat} onMouseEnter={() => cursorChangeHandler("hovered")} onMouseLeave={() => cursorChangeHandler("")}>
                <div className="row text-center" >
                    <div className={classNameMainData} ref={myBoatMainData} onMouseEnter={() => filterChangeHandler(true)} onMouseLeave={() => filterChangeHandler(false)}>
                        <p className={classNameBoatName} >
                            {boatData.boatName}
                        </p>
                        <p className="boat-data-label pb-2">
                            {localizationCtx.localizationData.labelLoa}
                        </p>
                        <p className="boat-data-value-normal">
                            {boatData.loa}
                        </p>
                    </div>
                </div>

                <div className="row text-center">
                    <div className={classNameBoxBoatImage}>
                        <div ref={myImgContainer} className={imgBoatContainerStyle}>
                            <img src={boatData.mainImage} alt={boatData.boatName} className={imgBoatStyle} ref={myBoatImage} />
                        </div>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12"><p>&nbsp;</p></div>
                </div>
                <div className="row pt-5 d-block d-lg-none">
                    <div className="col-12"><p>&nbsp;</p></div>
                </div>
            </div>

            <div className="boat-card-zoom-container" ref={myBoatZoom}>
                <div className="boat-card-zoom">
                    <img src={imgBackgroundUrl} alt={boatData.boatName} className="boat-img-zoom" ref={myBoatImageZoom} />
                </div>
                <div className="col-12 boat-main-data-zoom boat-main-data-right-zoom" ref={myBoatNameZoom}>
                    <p className="pb-5 boat-name-right d-none d-sm-block">
                        {boatData.boatName}
                    </p>
                    <p className="pb-5 boat-name-left d-block d-sm-none">
                        {boatData.boatName}
                    </p>
                </div>


                {(boatData.photoGallery === true) &&
                    <div className="col-12 boat-photogallery-container" ref={myBoatPhotogallery}>
                        <div className="row d-flex justify-content-center">
                            <div className="col-12">
                                <Swiper
                                    modules = {[Navigation, Pagination, Scrollbar, A11y]}
                                    initialSlide={0}
                                    slidesPerView={numImages}
                                    spaceBetween={10}
                                    enabled={photogallerySwiperEnabled}
                                    pagination={{
                                        clickable: true,
                                        bulletClass: 'swiper-pagination-bullet-gallery',
                                        bulletActiveClass: 'swiper-pagination-bullet-gallery-active',
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + '"></span>';
                                        },
                                    }}
                                >
                                    {boatData.imagesList.map((image, i) => (
                                        <SwiperSlide key={i}>
                                            <div className="text-center">
                                                <ImgBoat imageurl={image.url} animateZoomCallBack={animateZoomCallBack} />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                }

                <div className="col-12 col-lg-6 boat-data-container" ref={myBoatDataZoom}>
                    <div className="row">
                        {boatData.yachtId !== "" &&
                            <div className="col-5 px-1 py-1 col-lg-4 py-lg-3">
                                <p className="boat-data-label pb-1 pb-lg-2">{localizationCtx.localizationData.labelHullNumber}</p>
                                <p className="boat-data-value"> {boatData.yachtId}</p>
                            </div>
                        }
                        <div className="col-5 px-1 py-1 col-lg-4 py-lg-3">
                            <p className="boat-data-label pb-1 pb-lg-2">{localizationCtx.localizationData.labelLoa}</p>
                            <p className="boat-data-value-normal"> {boatData.loa}</p>
                        </div>
                        <div className="col-5 px-1 py-1 col-lg-4 py-lg-3">
                            <p className="boat-data-label pb-1 pb-lg-2">{localizationCtx.localizationData.labelYear}</p>
                            <p className="boat-data-value"> {boatData.yearLaunch}</p>
                        </div>
                        <div className="col-5 px-1 py-1 col-lg-4 py-lg-3">
                            <p className="boat-data-label pb-1 pb-lg-2">{localizationCtx.localizationData.labelNavalArchitect}</p>
                            <p className="boat-data-value"> {boatData.navalArchitect}</p>
                        </div>
                        <div className="col-5 px-1 py-1 col-lg-4 py-lg-3">
                            <p className="boat-data-label pb-1 pb-lg-2">{localizationCtx.localizationData.labelExteriorDesign}</p>
                            <p className="boat-data-value"> {boatData.exteriorDesign}</p>
                        </div>
                        <div className="col-5 px-1 py-1 col-lg-4 py-lg-3">
                            <p className="boat-data-label pb-1 pb-lg-2">{localizationCtx.localizationData.labelInteriorDesign}</p>
                            <p className="boat-data-value"> {boatData.interiorDesign}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 px-1 col-lg-6 py-1 py-lg-3">
                            <p className="boat-data-label pb-1 pb-lg-2">{localizationCtx.localizationData.labelMaterial}</p>
                            <p className="boat-data-value"> {boatData.materialEng}</p>
                        </div>
                    </div>
                </div>



                <div>
                    <div className="action-button back-button" ref={myActionZoom}>
                        <a ref={myActionBack} onMouseEnter={() => cursorChangeHandler("hide")} onMouseLeave={() => cursorChangeHandler("")} >{localizationCtx.localizationData.actionBack}</a>
                    </div>
                    {withLink &&
                        <div className="action-button more-info-button" onMouseEnter={() => cursorChangeHandler("hide")} onMouseLeave={() => cursorChangeHandler("")}>
                            <a href={boatLink} target="_parent">{localizationCtx.localizationData.actionMoreInfo}</a>
                        </div>
                    }
                </div>




                <div className="col-12 container-image-zoom" ref={myContainerImageGalleryZoom}>
                    <img src={myImage} className="img-gallery" ref={myImageGalleryZoom} onMouseEnter={() => cursorChangeHandler("zoom-out")} onMouseLeave={() => cursorChangeHandler("")} />
                </div>
            </div>
        </div>

    );
};

export default CardBoat;

