import React, { useRef, useEffect, useState, useContext, useLayoutEffect } from "react";
import * as Utilities from '../Shared/Utilities';
import { Parser } from 'html-to-react'
import { MouseContext } from "../MouseContext";
import LocalizationContext from "../LocalizationContext";
import { gsap, Power1, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const CardFeaturedBoat = (props) => {
    const localizationCtx = useContext(LocalizationContext);
    const { cursorChangeHandler } = useContext(MouseContext);

    const [boatData, setBoatData] = useState(props.boatData);
    const [boatLink, setBoatLink] = useState("");
    const [imgBoatContainerStyle, setImgBoatContainerStyle] = useState("");

    const myContainer = useRef();
    const myFeaturedBoat = useRef();
    const myBoatImage = useRef();
    const myBoatSheet = useRef();

    const loadLink = async () => {
        if (boatData.link !== "") {
            const response = await fetch("/api/model/linkmodel/" + boatData.link + "/" + localizationCtx.localizationData.cultureCode);

            if (response.ok) {
                const data = await response.text();
                if (data) {
                    setBoatLink(data);
                }

            } else {
                const error = await response.text();
                console.log(error);
            }
        }
    }

    useEffect(() => {
        let imageStyle = "col-12 box-boat-image-left";

        if (boatData.mainImageColor === true) {
            imageStyle += " image-boat-props-color";
        }

        setImgBoatContainerStyle(imageStyle);

        loadLink();

    }, []);


    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            if (Utilities.isMobile || Utilities.isTablet) {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 90%',
                        end: "top 65%",
                        scrub: 1,
                        markers: false,
                        id: "boat-in",
                        onToggle: self => self.isActive && setValue(),
                    }
                });

                tl.to(myFeaturedBoat.current, { opacity: 1 });

                const t2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 20%',
                        end: 'top top-=100',
                        scrub: 1,
                        markers: false,
                        id: "boat-out",
                    }
                });

                t2.to(myFeaturedBoat.current, { opacity: 0.2 });
            }



            if (Utilities.isLaptop) {

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 90%',
                        end: "top 65%",
                        scrub: 1,
                        markers: false,
                        id: "boat-in",
                        onToggle: self => self.isActive && setValue(),
                        onEnter: self => setProgressBar(self.direction),
                        onEnterBack: self => setProgressBar(self.direction),
                    }
                });


                tl.to(myFeaturedBoat.current, { opacity: 1, scale: 1 });
                tl.to(myBoatImage.current, { x: -700, duration: 4 }, "<");
                tl.to(myBoatSheet.current, { y: -550, duration: 4 }, "<");


                const t2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 20%',
                        end: 'top top-=100',
                        scrub: 1,
                        markers: false,
                        id: "boat-out",
                    }
                });

                t2.to(myFeaturedBoat.current, { opacity: 0.2, scale: 0.5 });
            }


            if (Utilities.isTabletH) {

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 90%',
                        end: "top 65%",
                        scrub: 1,
                        markers: false,
                        id: "boat-in",
                        onToggle: self => self.isActive && setValue(),
                        onEnter: self => setProgressBar(self.direction),
                        onEnterBack: self => setProgressBar(self.direction),
                    }
                });


                tl.to(myFeaturedBoat.current, { opacity: 1, scale: 1 });
                tl.to(myBoatImage.current, { x: -370, duration: 4 }, "<");
                tl.to(myBoatSheet.current, { y: -300, duration: 4 }, "<");


                const t2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 20%',
                        end: 'top top-=100',
                        scrub: 1,
                        markers: false,
                        id: "boat-out",
                    }
                });

                t2.to(myFeaturedBoat.current, { opacity: 0.2, scale: 0.5 });
            }

            if (Utilities.isSmallLaptop) {

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 90%',
                        end: "top 65%",
                        scrub: 1,
                        markers: false,
                        id: "boat-in",
                        onToggle: self => self.isActive && setValue(),
                        onEnter: self => setProgressBar(self.direction),
                        onEnterBack: self => setProgressBar(self.direction),
                    }
                });


                tl.to(myFeaturedBoat.current, { opacity: 1, scale: 1 });
                tl.to(myBoatImage.current, { x: -540, duration: 4 }, "<");
                tl.to(myBoatSheet.current, { y: -450, duration: 4 }, "<");


                const t2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".boat-card",
                        start: 'top 20%',
                        end: 'top top-=100',
                        scrub: 1,
                        markers: false,
                        id: "boat-out",
                    }
                });

                t2.to(myFeaturedBoat.current, { opacity: 0.2, scale: 0.5 });
            }

        }, myContainer);

        return () => ctx.revert();
    }, []);

    const setValue = () => {
        props.updateValueCallBack(boatData.yearLaunch, { startYear: boatData.refDecade.startYear, endYear: boatData.refDecade.endYear });
    };

    const getTitle = () => {
        let text = boatData.textTitleEng;

        if (localizationCtx.localizationData.cultureCode === "it-it") {
            text = boatData.textTitleIta;
        }
        if (localizationCtx.localizationData.cultureCode === "fr-fr") {
            text = boatData.textTitleFra;
        }
        if (localizationCtx.localizationData.cultureCode === "es-es") {
            text = boatData.textTitleSpa;
        }
        if (localizationCtx.localizationData.cultureCode === "ru-ru") {
            text = boatData.textTitleRus;
        }
        if (localizationCtx.localizationData.cultureCode === "ja-jp") {
            text = boatData.textTitleJap;
        }
        if (localizationCtx.localizationData.cultureCode === "pt-pt") {
            text = boatData.textTitlePor;
        }
        if (localizationCtx.localizationData.cultureCode === "de-de") {
            text = boatData.textTitleDeu;
        }
        if (localizationCtx.localizationData.cultureCode === "zh-cn") {
            text = boatData.textTitleCin;
        }

        return text;
    }

    const getDescription = () => {
        let text = boatData.textDescriptionEng;

        if (localizationCtx.localizationData.cultureCode === "it-it") {
            text = boatData.textDescriptionIta;
        }
        if (localizationCtx.localizationData.cultureCode === "fr-fr") {
            text = boatData.textDescriptionFra;
        }
        if (localizationCtx.localizationData.cultureCode === "es-es") {
            text = boatData.textDescriptionSpa;
        }
        if (localizationCtx.localizationData.cultureCode === "ru-ru") {
            text = boatData.textDescriptionRus;
        }
        if (localizationCtx.localizationData.cultureCode === "ja-jp") {
            text = boatData.textDescriptionJap;
        }
        if (localizationCtx.localizationData.cultureCode === "pt-pt") {
            text = boatData.textDescriptionPor;
        }
        if (localizationCtx.localizationData.cultureCode === "de-de") {
            text = boatData.textDescriptionDeu;
        }
        if (localizationCtx.localizationData.cultureCode === "zh-cn") {
            text = boatData.textDescriptionCin;
        }


        return text;
    }

    const setProgressBar = (direction) => {
        props.updateProgressBarCallBack(direction * 0.25 / props.boatsPerDecade);
    }




    return (
        <div id={props.idBoat} ref={myContainer}>
            <div id={props.boatData.link}></div>
            <div className="boat-card boat-featured-card" ref={myFeaturedBoat}>
                <div className="row text-center d-none d-lg-block">
                    <div className={imgBoatContainerStyle} ref={myBoatImage} onMouseEnter={() => cursorChangeHandler("hovered")} onMouseLeave={() => cursorChangeHandler("")}>
                        <a href={boatLink} target="_parent" className="boat-featured-link">
                            <img src={boatData.mainImage} alt={boatData.boatName} className="image-boat-props" />
                        </a>
                    </div>
                    <div className="text-center pb-3" ref={myBoatSheet} >
                        <div className="col-12 featured-boat-main-data-left">
                            <p className="pb-5 pb-lg-3 pb-xl-5 boat-name-left">
                                {boatData.boatName}
                            </p>
                            <p className="boat-data-label pb-2">
                                {localizationCtx.localizationData.labelLoa}
                            </p>
                            <p className="boat-data-value-normal">
                                {boatData.loa}
                            </p>
                        </div>
                        <div className="col-12 box-featured-boat" onMouseEnter={() => cursorChangeHandler("hovered")} onMouseLeave={() => cursorChangeHandler("")}>
                            <a href={boatLink} target="_parent" className="boat-featured-link">
                                <p className="title-featured-boat">
                                    {getTitle()}
                                </p>
                                <p className="text-featured-boat">
                                    {Parser().parse(getDescription())}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row text-center d-block d-lg-none">
                    <div className="col-12 featured-boat-main-data-left">
                        <p className="pb-2 boat-name-left">
                            {boatData.boatName}
                        </p>
                        <p className="boat-data-label pb-2">
                            {localizationCtx.localizationData.labelLoa}
                        </p>
                        <p className="boat-data-value-normal">
                            {boatData.loa}
                        </p>
                    </div>
                    <div className={imgBoatContainerStyle}>
                        <a href={boatLink} target="_parent" className="boat-featured-link">
                            <img src={boatData.mainImage} alt={boatData.boatName} className="image-boat-props" />
                        </a>
                    </div>
                    <div className="text-center pb-3">
                        <div className="col-12">
                            <a href={boatLink} target="_parent" className="boat-featured-link">
                                <p className="title-featured-boat pb-2">
                                    {getTitle()}
                                </p>
                                <p className="text-featured-boat">
                                    {Parser().parse(getDescription())}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12"><p>&nbsp;</p></div>
                </div>
            </div>

        </div>
    );
};
export default CardFeaturedBoat;