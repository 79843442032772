import React, { useEffect, useState, useContext, useLayoutEffect, useRef } from "react";
import { Parser } from 'html-to-react'
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import SeoTitles from './SeoCrnHeritage';

import '../../css/crn/main.css';
import '../../css/crn/animation.css';
import '../../css/crn/animation-576.css';
import '../../css/crn/animation-992.css';
import '../../css/crn/animation-1200.css';
import '../../css/crn/animation-1440.css';


import Decades from '../Shared/data/decades.json';
import Boats from '../Shared/data/boats.json';

import * as Utilities from '../Shared/Utilities';

import CursorCrnHeritage from "../Cursors/CursorCrnHeritage";
import { MouseContext } from "../MouseContext";

import Localizations from '../Shared/localization/CrnHeritage.json';
import LocalizationContext from "../LocalizationContext";

import YearSeparator from '../../css/_img/crn/line-year.svg';
import PeriodBorder from '../../css/_img/crn/white-line.svg';
import RowsLeft from "../../css/_img/crn/rows-left.svg"


import CrnMenu from "./CrnMenu";

import { Decade } from "../Timeline/Decade";
import { CardBoat } from "../Timeline/CardBoat";
import { CardFeaturedBoat } from "../Timeline/CardFeaturedBoat";
import { ItemTimeline } from "../Timeline/ItemTimeline";


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);


export const CrnHeritage = (props) => {
    const { cursorChangeHandler } = useContext(MouseContext);
    const [cursorType, setCursorType] = useState("hovered");
    const localizationCtx = useContext(LocalizationContext);

    const { idBoat } = useParams();

    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const [classDivAction, setClassDivAction] = useState("col-1 col-lg-5 d-flex justify-content-center");
    const [classDivContent, setClassDivContent] = useState("col-10 col-lg-6");
    const [classDivTimeline, setClassDivTimeline] = useState("col-1 timeline-container");
    const [classMenu, setClassMenu] = useState("opaque");
    const [classToggleMenu, setClassToggleMenu] = useState("");
    const [bodyBackgroudColor, setBodyBackgroudColor] = useState('body { background-color: #dddddd !important; }');

    const myActionRestart = useRef();
    const myActionDiscover = useRef();
    const myVideoIntro = useRef();
    const myVideo = useRef();

    const myContainer = useRef();
    const myPeriod = useRef();
    const myPeriodBar = useRef();
    const myActionBox = useRef();
    const myTimeLineContainer = useRef();
    const myProgressBar = useRef();
    const myRefOffset = useRef();

    const [isVideoIntro, setIsVideoIntro] = useState(true);
    const [myDecade, setMyDecade] = useState({ startYear: 1963, endYear: 1969 });
    const [myYear, setMyYear] = useState(1963);

    const setDecade = (decade) => {
        setMyYear(decade.startYear);
        setMyDecade(decade);
    }

    const setYearAndDecade = (year, decade) => {
        setMyYear(year);
        setMyDecade(decade);
    }

    const initHeritageStage = () => {
        cursorChangeHandler(cursorType);
        myActionDiscover.current.addEventListener("click", handleStartAnimation);
        myVideo.current.addEventListener("click", handleStartAnimation);
    };

    useEffect(() => {
        const myLocalization = Localizations.filter(function (local) {
            return local.cultureCode === props.cultureCode;
        });

        if (myLocalization.length === 1) {
            localizationCtx.updateLocalizationData(myLocalization[0]);
        }


        if (window.matchMedia("(any-hover: none)").matches) {
            setIsTouchDevice(true);
        }

        let tempClassAction = classDivAction + " div-action";
        let tempClassCentent = classDivContent + " div-content";
        let tempClassTimeline = classDivTimeline + " div-timeline";

        if (Utilities.isMobile) {
            setClassDivAction(tempClassAction);
            setClassDivContent(tempClassCentent);
            setClassDivTimeline(tempClassTimeline);
        }

        if (Utilities.isTablet) {
            setClassDivAction(tempClassAction);
            setClassDivTimeline(tempClassTimeline);
        }

        if (idBoat === undefined || idBoat === null) {
            if (isVideoIntro) {
                initHeritageStage();
            }
        } else {
            scrollToBoat();
        }

    }, []);



    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const decades = self.selector('.idDecade');
            decades.forEach((decade) => {

                if (Utilities.isMobile || Utilities.isTablet) {
                    const t1 = gsap.timeline({
                        scrollTrigger: {
                            trigger: decade,
                            start: 'top 75%',
                            end: 'top 65%',
                            scrub: 0.5,
                            markers: false,
                            id: "period-out",
                        }
                    });

                    t1.to(myPeriod.current, { opacity: 0 });

                    const t2 = gsap.timeline({
                        scrollTrigger: {
                            trigger: decade,
                            start: 'top 10%',
                            end: 'top top',
                            scrub: 0.5,
                            markers: false,
                            id: "period-out",
                        }
                    });

                    t2.to(myPeriod.current, { opacity: 1 });
                }

                if (Utilities.isLaptop || Utilities.isSmallLaptop || Utilities.isTabletH) {

                    const t1 = gsap.timeline({
                        scrollTrigger: {
                            trigger: decade,
                            start: 'top center',
                            end: 'top 255px',
                            scrub: 0.5,
                            markers: false,
                            id: "period-x-in",
                        }
                    });


                    t1.to(myPeriod.current, { opacity: 0, x: 350, duration: 2 })
                    t1.to(myPeriodBar.current, { opacity: 0, width: 650, duration: 2 }, "<")

                    const t2 = gsap.timeline({
                        scrollTrigger: {
                            trigger: decade,
                            start: 'top 50px',
                            end: "+=1000",
                            scrub: 1,
                            markers: false,
                            id: "period-x-out",

                        }
                    });

                    t2.to(myPeriod.current, { opacity: 1, x: 0, duration: 2 })
                    t2.to(myPeriodBar.current, { opacity: 1, width: 220, duration: 2 }, "<")
                }

            });
        }, myContainer);
        return () => ctx.revert();
    }, [isVideoIntro]);

    const handleRestart = () => {
        animateVideoIntroReverse();
    };

    const handleStartAnimation = () => {
        animateVideoIntro("decade001");
    };

    const animateVideoIntro = (scrollPosition) => {
        const tl = gsap.timeline();

        const onCompleteAnimation = () => {
            setIsVideoIntro(false);
            setBodyBackgroudColor('body { background-color: #333333 !important; }');
            setClassMenu("transparent");
            setClassToggleMenu("-white");
            myActionRestart.current.addEventListener("click", handleRestart);
            animateProgressBar(0);
            setCursorType("");
            cursorChangeHandler("");
        }

        const style = {
            duration: 1,
            opacity: 0,
            onComplete: onCompleteAnimation
        };

        tl.to(myVideoIntro.current, style);
        if (Utilities.isMobile) {
            tl.to(window, { scrollTo: { y: "#" + scrollPosition, offsetY: Utilities.GetScrollToYOffset(myRefOffset.current) }, ease: "power2" });
        } else {
            tl.to(window, { scrollTo: { y: "#" + scrollPosition, offsetY: Utilities.GetScrollToYOffset(null) }, ease: "power2" });
        }

    };

    const scrollToBoat = () => {
        animateVideoIntro(idBoat);
    };

    const animateVideoIntroReverse = () => {
        setIsVideoIntro(true);
        setBodyBackgroudColor('body { background-color: #dddddd !important; }');
        setClassMenu("opaque");
        setClassToggleMenu("");
        initHeritageStage();

        const tl = gsap.timeline();
        tl.set(myVideoIntro.current, { opacity: 0 });
        tl.to(myVideoIntro.current, { duration: 1, opacity: 1 });
    };

    const animatePeriodBox = (boxOpacity) => {

        const onCompleteAnimation = () => {
            if (boxOpacity === 1) {
                tl.set(myActionBox.current, { visibility: "visible" });
            } else {
                tl.set(myActionBox.current, { visibility: "hidden" });
            }
        }

        const tl = gsap.timeline();
        tl.to(myActionBox.current, { duration: 1, opacity: boxOpacity, onComplete: onCompleteAnimation });
        tl.to(myTimeLineContainer.current, { duration: 1, opacity: boxOpacity }, "<");



    };


    let progressBarValue = 0;

    const animateProgressBar = (value) => {
        if (Utilities.isLaptop || Utilities.isSmallLaptop || Utilities.isTabletH) {
            progressBarValue += value;

            if (progressBarValue < 0) {
                progressBarValue = 0;
            }

            if (progressBarValue > 1) {
                progressBarValue = 1;
            }

            const tl = gsap.timeline();

            tl.set(myProgressBar.current, {
                scaleY: progressBarValue,
                transformOrigin: "0px 0px"
            });
        }
    }

    const handleClickTimeline = (point) => {
        const tl = gsap.timeline();

        tl.to(window, { scrollTo: { y: "#" + point, offsetY: Utilities.GetScrollToYOffset() }, ease: "power2" });
        if (point === "decade005") {
            if (Utilities.isLaptop || Utilities.isSmallLaptop || Utilities.isTabletH) {
                tl.to(myPeriod.current, { opacity: 0, x: 350, duration: 2 })
                tl.to(myPeriodBar.current, { opacity: 0, width: 650, duration: 2 }, "<")
            }
        }
    }

    let viewDecade = false;
    let currentDecadeYearStart = 0;

    const renderTimeline = (decadesList) => {
        const myRender = decadesList.map((decade, i) => {
            const myKey = "timeline-" + i;
            if (i != decadesList.length - 1) {
                if (i !== 0) {
                    return (
                        <div key={myKey}>
                            <ItemTimeline callBackTimeline={handleClickTimeline} scrollToPosition={decade.id} text={decade.startYear} isLastItem={false} />

                            {(i === decadesList.length - 2) &&
                                <ItemTimeline callBackTimeline={handleClickTimeline} scrollToPosition={decadesList[decadesList.length - 1].id} text="" isLastItem={true} />
                            }
                        </div>
                    )
                }

                if (i === 0) {
                    return (
                        <div key={myKey}>
                            <ItemTimeline callBackTimeline={handleClickTimeline} scrollToPosition={decade.id} text="" isLastItem={false} />
                        </div>
                    )
                }
            }
        });

        return myRender;
    }


    const renderHeritage = (boatsList, decadesList) => {

        const boatCounter = (filterDecade) => {
            const filteredBoat = boatsList.filter(b => {
                return b.decade === filterDecade;
            });

            return filteredBoat.length;
        };

        let indexDecade = 0;
        const myRender = boatsList.map((boat, i) => {
            const filterDecades = decadesList.filter((d) => {
                return d.id === boat.decade;
            });

            if (filterDecades.length === 1) {
                boat.refDecade = filterDecades[0];
            }

            let dataPosition = "right";
            let imagePosition = "left";

            if (i % 2 === 1) {
                dataPosition = "left";
                imagePosition = "right";
            }

            if (filterDecades[0].startYear !== currentDecadeYearStart) {
                viewDecade = true;
                currentDecadeYearStart = filterDecades[0].startYear;
                indexDecade++;
            } else {
                viewDecade = false;
            }

            const boatsPerDecade = boatCounter(boat.decade);

            const myBoatKey = "boat-" + i;
            const myDecadeKey = "decade-" + indexDecade;

            let idBoat = myBoatKey;

            let withLink = false;
            if (boat.link !== "") {
                withLink = true;
            }


            if (i === boatsList.length - 1) { // Last boat in array is a dummy
                idBoat = "lastBoat";
            }


            if (viewDecade && idBoat === "lastBoat") {
                return (
                    <>
                        <div key={myDecadeKey} className="idDecade py-5">
                            <Decade decade={filterDecades[0]} updateValueCallBack={setDecade} />
                        </div>
                    </>
                )
            }

            if (viewDecade && !boat.featured) {
                return (
                    <>
                        <div key={myDecadeKey} className="idDecade py-5">
                            <Decade decade={filterDecades[0]} updateValueCallBack={setDecade} />
                        </div>
                        <CardBoat key={myBoatKey} idBoat={idBoat} boatData={boat} withLink={withLink} dataPosition={dataPosition} imagePosition={imagePosition} className="idBoat"
                            updateValueCallBack={setYearAndDecade} animateCallBack={animatePeriodBox} updateProgressBarCallBack={animateProgressBar} boatsPerDecade={boatsPerDecade} />
                    </>
                )
            }

            if (!viewDecade && !boat.featured) {
                return (
                    <CardBoat key={myBoatKey} idBoat={idBoat} boatData={boat} withLink={withLink} dataPosition={dataPosition} imagePosition={imagePosition} className="idBoat"
                        updateValueCallBack={setYearAndDecade} animateCallBack={animatePeriodBox} updateProgressBarCallBack={animateProgressBar} boatsPerDecade={boatsPerDecade} />

                )
            }

            if (!viewDecade && boat.featured) {
                return (
                    <CardFeaturedBoat key={myBoatKey} idBoat={idBoat} boatData={boat} withLink={withLink} className="idFeaturedBoat"
                        updateValueCallBack={setYearAndDecade} updateProgressBarCallBack={animateProgressBar} boatsPerDecade={boatsPerDecade} />
                )
            }

            if (viewDecade && boat.featured) {
                return (
                    <>
                        <div key={myDecadeKey} className="idDecade py-5">
                            <Decade decade={filterDecades[0]} updateValueCallBack={setDecade} animation={myPeriod} />
                        </div>
                        <CardFeaturedBoat key={myBoatKey} idBoat={idBoat} boatData={boat} withLink={withLink} className="idFeaturedBoat"
                            updateValueCallBack={setYearAndDecade} updateProgressBarCallBack={animateProgressBar} boatsPerDecade={boatsPerDecade} />
                    </>
                )
            }
        });

        return myRender;
    };


    return (

        <>
            <Helmet htmlAttributes={{ lang: props.cultureCode }} >
                <title>{SeoTitles.Home}</title>
                <style>{bodyBackgroudColor}</style>

            </Helmet>

            {!isTouchDevice && <CursorCrnHeritage />}
            <CrnMenu dynamicMainClass={classMenu} dynamicToggleClass={classToggleMenu} cultureCode={props.cultureCode} cursorType={cursorType} />
            {isVideoIntro &&
                <>
                    <a ref={myVideo} className="no-cursor">
                        <div ref={myVideoIntro}>
                            <div className="container-fluid px-0">
                                <video className="video-intro" src="/videos/crn/heritage_video_20.mp4" autoplay="true" muted="true" loop="true"></video>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="page-payoff">
                                        <h1 className="h1 title-section"> {Parser().parse(localizationCtx.localizationData.title)}</h1>
                                        <h3 className="title-paragraph pb-3"><span>{Parser().parse(localizationCtx.localizationData.subTitle)}</span></h3>
                                        <p className="text-section pb-4">
                                        {Parser().parse(localizationCtx.localizationData.payOff)}
                                        </p>
                                        <div><img src={RowsLeft} /></div>
                                        <div className="action-button discover-button pt-3 pt-lg-5 pb-5" onMouseEnter={() => cursorChangeHandler("hide")} onMouseLeave={() => cursorChangeHandler("hovered")}>
                                            <p ref={myActionDiscover}>{localizationCtx.localizationData.actionDiscover}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="crn-space">

                            </div>
                        </div>
                    </a>
                </>
            }

            {!isVideoIntro &&
                <div className="container-fluid bg-crn">
                    <div className="container-main">
                        <div className="row py-5">
                            <div className={classDivAction} ref={myActionBox}>
                                <div className="col-12 d-block d-lg-none period-gradient-top"></div>

                                <div className="period-decade">
                                    <div className="text-center" ref={myPeriod}>
                                        <p className="text-period">
                                            {myDecade.startYear} <img src={YearSeparator} alt="separator" /> {Utilities.FormatEndYear(myDecade.endYear, localizationCtx.localizationData.cultureCode)}
                                        </p>
                                        <div className="text-period-bar" ref={myPeriodBar}></div>
                                    </div>
                                    <p className="year-launch d-none d-lg-block" >
                                        {myYear}
                                    </p>
                                </div>
                                <div className="col-12 d-block d-lg-none period-gradient-bottom"></div>
                                <div className="action-button back-button" >
                                <a ref={myActionRestart} onMouseEnter={() => cursorChangeHandler("hide")} onMouseLeave={() => cursorChangeHandler("")}>{localizationCtx.localizationData.actionRestart}</a>
                                </div>
                            </div>
                            <div className={classDivContent}>
                                <div ref={myContainer}>
                                    {renderHeritage(Boats, Decades)}
                                </div>
                            </div>
                            <div className={classDivTimeline} ref={myTimeLineContainer}>
                                <div className="start-timeline" ref={myRefOffset} >
                                    <p className="timeline-main-text timeline-start-text">{myYear}</p>
                                </div>
                                <div className="progressBar d-none d-lg-block" ref={myProgressBar}></div>
                                <div className="body-timeline">
                                    {renderTimeline(Decades)}
                                </div>
                                <div className="end-timeline">
                                    <p className="timeline-main-text d-none d-sm-block">2000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default CrnHeritage;
