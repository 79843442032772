import React, { useRef, useEffect, useState, useContext, useLayoutEffect } from "react";
import * as Utilities from '../Shared/Utilities';

import { MouseContext } from "../MouseContext";
import StepTimeline from "../../css/_img/crn/step-timeline.svg";
import StepTimelineOver from "../../css/_img/crn/step-timeline-over.svg";

export const ItemTimeline = (props) => {
    const { cursorChangeHandler } = useContext(MouseContext);
    const [imgItem, setImgItem] = useState(StepTimeline);
    const [isTooltipShown, setIsTooltipShown] = useState(false);
    const mainClassTooltip = "item-text-timeline";
    
    const getTooltipClass = (status) => {
        return mainClassTooltip + " " + mainClassTooltip + "-" + status;
    }

    const [classTooltip, setClassTooltip] = useState(getTooltipClass("hidden"));


    const handleMouseOver = () => {
        setClassTooltip(getTooltipClass("show"));
        setIsTooltipShown(true);
        setImgItem(StepTimelineOver);
        cursorChangeHandler("hide");
    }
    const handleMouseOut = () => {
        setClassTooltip(getTooltipClass("hidden"));
        setIsTooltipShown(false);
        setImgItem(StepTimeline);
        cursorChangeHandler("");
    }

    return (
        <>
            {!props.isLastItem &&
                <div className="row item-timeline">
                    <div className="text-end">
                        {!Utilities.isMobile && props.text !== "" &&
                            <div className={classTooltip} >{props.text}</div>
                        }
                        <div className="item-mark-timeline">
                            <img src={imgItem} onClick={() => props.callBackTimeline(props.scrollToPosition)} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
                        </div>
                    </div>
                </div>
            }

            {props.isLastItem &&
                <div className="row item-timeline-end">
                    <div className="text-end">
                        <img src={imgItem} className="item-mark-timeline" onClick={() => props.callBackTimeline(props.scrollToPosition)} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
                    </div>
                </div>
            }
        </>
    );
};

export default ItemTimeline;

