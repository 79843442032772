import React, { useEffect, useState, useContext } from "react";
import Localizations from '../Shared/localization/CrnHeritage.json';
import LogoCrn from "../../css/_img/crn/logo_CRN_sticky_menu.svg"
import MenuNewDesignImage from "../../css/_img/crn/menu_new_design.jpg"
import MenuNewBuildsImage from "../../css/_img/crn/menu_new_builds.jpg"
import { MouseContext } from "../MouseContext";
import { Parser } from 'html-to-react'

export const CrnMenu = (props) => {
    const { cursorChangeHandler } = useContext(MouseContext);
    const [showNavMenu, setShowNavMenu] = useState(false);
    const [showNavSubMenu, setShowNavSubMenu] = useState(false);
    const [prefixMenuLink, setPrefixMenuLink] = useState("");
    const [labelsMenu, setLabelsMenu] = useState({
        menuPhilosophy: "",
        menuHeritage: "",
        menuMegayachts: "",
        menuBuild: "",
        menuDesign: "",
        menuServices: "",
        menuRefit: "",
        menuNewsEvent: "",
        menuContacts: "",
        menuBuildExt: "",
        menuDesignExt: ""
    });

    const mainClassNavBar = "navbar-crn fixed-top navbar navbar-expand-lg navbar-light bg-light " + props.dynamicMainClass;
    const [classToggleIcon, setClassToggleIcon] = useState("");

    const loadLink = async () => {
        let req = "/api/model/linkmodel/MENU/" + props.cultureCode;
        const response = await fetch(req);

        if (response.ok) {
            const data = await response.text();
            if (data) {
                setPrefixMenuLink(data);
            }

        } else {
            const error = await response.text();
            console.log(error);
        }
    }

    const toggleNavMenu = () => {
        setClassToggleIcon("navbar-toggler-icon");
        setShowNavMenu(!showNavMenu);
    }

    const toggleNavSubMenu = () => {
        setShowNavSubMenu(!showNavSubMenu);
    }

    const getItemMenu = (textItem, centered) => {
        let result = "";
        const myArray = textItem.split(" ");

        const myParse = (t, i) => {
            let space = "&nbsp;";
            if (i === 0) {
                result += '<span class="dynamicMenuItem">'+ (centered ? space : "") + t + space + '</span>';
            } else {
                result += '<span class="dynamicMenuItem">' + t + space + '</span>';
            }
        };

        myArray.forEach(myParse);


        return result;


    }

    useEffect(() => {
        setClassToggleIcon("navbar-toggler-icon" + props.dynamicToggleClass);
    }, [props.dynamicToggleClass]);

    useEffect(() => {
        const myLocalization = Localizations.filter(function (local) {
            return local.cultureCode === props.cultureCode;
        });

        if (myLocalization.length === 1) {
            setLabelsMenu(myLocalization[0]);
        }

        loadLink();
    }, []);


    return (
        <>
            <nav className={mainClassNavBar} onMouseEnter={() => cursorChangeHandler("hide")} onMouseLeave={() => cursorChangeHandler(props.cursorType)} >
                <div className="container-fluid padd pt-2">
                    <a className="navbar-brand" href={prefixMenuLink} >
                        <img src={LogoCrn} />
                    </a>
                    <button className="navbar-toggler" type="button" onClick={toggleNavMenu} data-bs-toggle="collapse" data-bs-target="#navbar-crn" aria-controls="navbar-crn" aria-expanded={showNavMenu} aria-label="Toggle navigation">
                        <span className={classToggleIcon}></span>
                    </button>
                    <div className={(showNavMenu ? 'show' : '') + ' collapse navbar-collapse'} id="navbar-crn">
                        <ul className="navbar-nav position-relative">
                            <li className="nav-item data-item" data-item="Our-philosophy-and-values">
                                <a className="nav-link" href={prefixMenuLink + "Our-philosophy-and-values"}>
                                    {Parser().parse(getItemMenu(labelsMenu.menuPhilosophy, true))}
                                </a>
                            </li>
                            <li className="nav-item data-item active" data-item="Crn-heritage">
                                <a className="nav-link" href={prefixMenuLink + "Crn-heritage"}>
                                    {Parser().parse(getItemMenu(labelsMenu.menuHeritage, true))}
                                </a>
                            </li>
                            <li className="nav-item data-item" data-item="Crn-megayachts">
                                <a className="nav-link" href={prefixMenuLink + "Crn-megayachts"}>
                                    {Parser().parse(getItemMenu(labelsMenu.menuMegayachts, true))}
                                </a>
                            </li>
                            <li className="nav-item data-item" data-item="Crn-new-builds-and-design">
                                <a className={(showNavSubMenu ? 'show' : '') + ' nav-link'} onClick={toggleNavSubMenu} id="navbarDropdownNewbuild" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {Parser().parse(getItemMenu(labelsMenu.menuBuild, true))}
                                </a>
                                <div className={(showNavSubMenu ? 'show' : '') + ' dropdown-menu dropdown-menu-crn'} aria-labelledby="navbarDropdownNewbuild">
                                    <div className="container position-relative delay-submenu">
                                        <div className="row justify-content-start">
                                            <div className="col-lg-4">
                                                <div className="row">
                                                    <div className="col-lg-6 pe-0">
                                                        <img src={MenuNewBuildsImage} className="w-100 d-none d-lg-block" />
                                                    </div>
                                                    <div className="col-lg-6 d-flex align-items-center">
                                                        <a className={(showNavSubMenu ? 'text-center' : '') + ' dropdown-item data-item nav-link dynamicMenuSubItemContainer'} data-item="Crn-new-builds" href={prefixMenuLink + "Crn-new-builds"}>{Parser().parse(getItemMenu(labelsMenu.menuBuildExt, false))}</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="row">
                                                    <div className="col-lg-6 pe-0">
                                                        <img src={MenuNewDesignImage} className="w-100 d-none d-lg-block" />
                                                    </div>
                                                    <div className="col-lg-6 d-flex align-items-center ">
                                                        <a className={(showNavSubMenu ? 'text-center' : '') + ' dropdown-item data-item nav-link dynamicMenuSubItemContainer'} data-item="Crn-new-design" href={prefixMenuLink + "Crn-new-design"}>{Parser().parse(getItemMenu(labelsMenu.menuDesignExt, false))}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item data-item crn-menu-item" data-item="Megayachts-services-and-refit">
                                <a className="nav-link" href={prefixMenuLink + "Megayachts-services-and-refit"}>
                                    {Parser().parse(getItemMenu(labelsMenu.menuServices, true))}
                                </a>
                            </li>
                            <li className="nav-item data-item crn-menu-item" data-item="News-and-events">
                                <a className="nav-link" href={prefixMenuLink + "News-and-events"}>
                                    {Parser().parse(getItemMenu(labelsMenu.menuNewsEvent, true))}
                                </a>
                            </li>
                            <li className="nav-item data-item crn-menu-item" data-item="Our-contacts">
                                <a className="nav-link" href={prefixMenuLink + "Our-contacts"}>
                                    {Parser().parse(getItemMenu(labelsMenu.menuContacts, true))}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default CrnMenu;
