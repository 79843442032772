import React, { useState } from "react";

export const LocalizationContext = React.createContext({
    localizationData: {
        cultureCode: "",
        idLanguage: "",
        title: "",
        subTitle: "",
        payOff: "",
        menuPhilosophy: "",
        menuHeritage: "",
        menuMegayachts: "",
        menuBuild: "",
        menuDesign: "",
        menuServices: "",
        menuRefit: "",
        menuNewsEvent: "",
        menuContacts: "",
        menuBuildExt: "",
        menuDesignExt: ""
    },
    updateLocalizationData: () => { },
});

export const LocalizationContextProvider = (props) => {
    const [localizationData, setLocalizationData] = useState({
        cultureCode: "",
        idLanguage: "",
        cultureCode: "",
        idLanguage: "",
        title: "",
        subTitle: "",
        payOff: "",
        menuPhilosophy: "",
        menuHeritage: "",
        menuMegayachts: "",
        menuBuild: "",
        menuDesign: "",
        menuServices: "",
        menuRefit: "",
        menuNewsEvent: "",
        menuContacts: "",
        menuBuildExt: "",
        menuDesignExt: ""
    });


    const UpdateLocalizationData = (localizationData) => {
        setLocalizationData(localizationData);
    };

    return (
        <LocalizationContext.Provider value={{
            localizationData: localizationData,
            updateLocalizationData: UpdateLocalizationData,
        }}>
            {props.children}
        </LocalizationContext.Provider>
    );
};

export default LocalizationContext;
