import React, { useState } from "react";
import { Route, Redirect } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home/Home";
import { CrnHeritage } from "./components/Home/CrnHeritage";
import { LocalizationContextProvider } from "./components/LocalizationContext";
import { MouseContextProvider } from "./components/MouseContext";
import CssBaseline from "@mui/material/CssBaseline";


export const App = () => {

    return (
        <>
            <MouseContextProvider>
                <LocalizationContextProvider>
                    <CssBaseline />
                    <Layout>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/en-us/CrnHeritage" render={(props) => <CrnHeritage cultureCode="en-us" />} />
                        <Route exact path="/en-us/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="en-us" />} />
                        <Route exact path="/it-it/CrnHeritage" render={(props) => <CrnHeritage cultureCode="it-it" />} />
                        <Route exact path="/it-it/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="it-it" />} />
                        <Route exact path="/fr-fr/CrnHeritage" render={(props) => <CrnHeritage cultureCode="fr-fr" />} />
                        <Route exact path="/fr-fr/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="fr-fr" />} />
                        <Route exact path="/es-es/CrnHeritage" render={(props) => <CrnHeritage cultureCode="es-es" />} />
                        <Route exact path="/es-es/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="es-es" />} />
                        <Route exact path="/zh-cn/CrnHeritage" render={(props) => <CrnHeritage cultureCode="zh-cn" />} />
                        <Route exact path="/zh-cn/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="zh-cn" />} />
                        <Route exact path="/ru-ru/CrnHeritage" render={(props) => <CrnHeritage cultureCode="ru-ru" />} />
                        <Route exact path="/ru-ru/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="ru-ru" />} />
                        <Route exact path="/ja-jp/CrnHeritage" render={(props) => <CrnHeritage cultureCode="ja-jp" />} />
                        <Route exact path="/ja-jp/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="ja-jp" />} />
                        <Route exact path="/pt-pt/CrnHeritage" render={(props) => <CrnHeritage cultureCode="pt-pt" />} />
                        <Route exact path="/pt-pt/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="pt-pt" />} />
                        <Route exact path="/de-de/CrnHeritage" render={(props) => <CrnHeritage cultureCode="de-de" />} />
                        <Route exact path="/de-de/CrnHeritage/boat/:idBoat" render={(props) => <CrnHeritage cultureCode="de-de" />} />
                        <Route exact path="/ClientApp">
                            <Redirect to="/" />
                        </Route>
                    </Layout>
                </LocalizationContextProvider>
            </MouseContextProvider>
        </>
    );
};

export default App;
